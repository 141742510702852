import type { SxProps, Theme } from '@mui/system';
import config from '@packages/config';
import {
  StyleTypeBottomLeft,
  StyleTypeBottomRight,
  StyleTypeLeft,
  StyleTypeRight,
  StyleTypeTopLeft,
  StyleTypeTopRight,
} from './styles';

export const styleHelperType = (
  styleType: string,
  transformBasicStyle: { xs: string; lg: string },
): SxProps<Theme> => {
  const transformVerticalStyle = {
    xs: `${transformBasicStyle.xs !== 'none' ? transformBasicStyle.xs : ''} translateX(0) translateY(-50%)`,
    lg: `${transformBasicStyle.lg !== 'none' ? transformBasicStyle.lg : ''} translateX(0) translateY(-50%)`,
  };
  switch (styleType) {
    case 'left':
      return {
        ...StyleTypeLeft,
        transform: transformVerticalStyle,
      };
    case 'top-left':
      return {
        ...StyleTypeTopLeft,
        transform: transformBasicStyle,
      };
    case 'top-right':
      return {
        ...StyleTypeTopRight,
        transform: transformBasicStyle,
      };
    case 'bottom-left':
      return {
        ...StyleTypeBottomLeft,
        transform: transformBasicStyle,
      };
    case 'bottom-right':
      return {
        ...StyleTypeBottomRight,
        transform: transformBasicStyle,
      };
    case 'right':
    default:
      return {
        ...StyleTypeRight,
        transform: transformVerticalStyle,
      };
  }
};

export const containerStyle = ({
  styleType = 'left',
  styleBackgroundColor,
  styleScalingFactor = 1,
}: {
  styleBackgroundColor?: string;
  styleScalingFactor?: number;
  styleType?: string;
}): SxProps<Theme> => {
  const transformBasicStyle = {
    xs: `scale(calc(${styleScalingFactor} + 0.35))`,
    lg: styleScalingFactor === 1 ? 'none' : `scale(${styleScalingFactor})`,
  };

  return {
    '&': config.staticContent.cmsFontToTheme,
    position: 'absolute',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    overflow: 'hidden',
    textAlign: 'center',
    textDecoration: 'none',
    '> a': {
      textDecoration: 'none',
    },
    width: {
      xs: '7.625rem',
      md: '15.25rem',
    },
    height: {
      xs: '7.625rem',
      md: '15.25rem',
    },
    backgroundColor: styleBackgroundColor || 'primary.dark',
    borderRadius: '50%',
    ...styleHelperType(styleType, transformBasicStyle),
  };
};
